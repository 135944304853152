<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Project-overview component
 */
export default {
  page: {
    title: "Project Overview",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Project Overview",
      items: [
        {
          text: "Projects",
        },
        {
          text: "Project Overview",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-5">
            <div class="row">
              <div class="col">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="avatar">
                      <div
                        class="avatar-title bg-soft-primary text-primary font-size-18 rounded"
                      >
                        D
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <div class="text-muted">
                      <h5 class="font-size-16 mb-1">Dashonic Admin</h5>
                      <p class="mb-0">
                        Neque porro quisquam est, quia dolor sit amet
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-auto">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3"
                >
                  <b-dropdown
                    variant="light"
                    menu-class="dropdown-menu-lg"
                    right
                  >
                    <template v-slot:button-content>
                      <i class=" uil uil-bell"></i
                      ><span
                        class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1"
                        ><span class="visually-hidden"
                          >unread messages</span
                        ></span
                      >
                    </template>
                    <div class="px-3 py-2">
                      <div class="row align-items-center">
                        <div class="col">
                          <h5 class="font-size-15 m-0">Notifications</h5>
                        </div>
                        <div class="col-auto">
                          <div class="badge bg-success rounded-pill">02</div>
                        </div>
                      </div>
                    </div>
                    <div data-simplebar style="max-height: 190px;">
                      <a href="" class="dropdown-item p-3">
                        <div class="d-flex align-items-start">
                          <div
                            class="flex-shrink-0 avatar-sm align-self-center me-3"
                          >
                            <img
                              src="@/assets/images/users/avatar-2.jpg"
                              alt=""
                              class="img-fluid rounded-circle"
                            />
                          </div>
                          <div class="flex-grow-1 overflow-hidden">
                            <h5 class="font-size-13 mb-1">James Lemire</h5>
                            <div class="font-size-12">
                              <p class="text-muted text-truncate mb-0">
                                If several languages coalesce the grammar
                              </p>
                            </div>
                          </div>
                          <p class="text-muted font-size-12 mb-0">
                            <i class="mdi mdi-clock-outline"></i> 3 Mins
                          </p>
                        </div>
                      </a>

                      <a href="" class="dropdown-item p-3">
                        <div class="d-flex align-items-start">
                          <div
                            class="flex-shrink-0 avatar-sm align-self-center me-3"
                          >
                            <div
                              class="avatar-title rounded-circle bg-light text-primary"
                            >
                              <i class="uil uil-bug"></i>
                            </div>
                          </div>
                          <div class="flex-grow-1 overflow-hidden">
                            <h5 class="font-size-13 mb-1">Bug Report</h5>
                            <div class="font-size-12">
                              <p class="text-muted text-truncate mb-0">
                                Donec pede justo, fringilla vel, aliquet nec,
                                vulputate eget
                              </p>
                            </div>
                          </div>
                          <p class="text-muted font-size-12 mb-0">
                            <i class="mdi mdi-clock-outline"></i> 2 Hrs
                          </p>
                        </div>
                      </a>

                      <a href="" class="dropdown-item p-3">
                        <div class="d-flex align-items-start">
                          <div
                            class="flex-shrink-0 avatar-sm align-self-center me-3"
                          >
                            <div
                              class="avatar-title rounded-circle bg-light text-primary"
                            >
                              <i class="uil uil-list-ul"></i>
                            </div>
                          </div>
                          <div class="flex-grow-1 overflow-hidden">
                            <h5 class="font-size-13 mb-1">
                              New Updates for Theme
                            </h5>
                            <div class="font-size-12">
                              <p class="text-muted text-truncate mb-0">
                                Etiam sit amet orci eget eros faucibus
                                tincidunt.
                              </p>
                            </div>
                          </div>
                          <p class="text-muted font-size-12 mb-0">
                            <i class="mdi mdi-clock-outline"></i> 4 Hrs
                          </p>
                        </div>
                      </a>

                      <a href="" class="dropdown-item p-3">
                        <div class="d-flex align-items-start">
                          <div
                            class="flex-shrink-0 avatar-sm align-self-center me-3"
                          >
                            <img
                              src="@/assets/images/users/avatar-4.jpg"
                              alt=""
                              class="img-fluid rounded-circle"
                            />
                          </div>
                          <div class="flex-grow-1 overflow-hidden">
                            <h5 class="font-size-13 mb-1">Salena Layfield</h5>
                            <div class="font-size-12">
                              <p class="text-muted text-truncate mb-0">
                                Aenean leo ligula, porttitor eu, consequat
                                vitae, eleifend ac
                              </p>
                            </div>
                          </div>
                          <p class="text-muted font-size-12 mb-0">
                            <i class="mdi mdi-clock-outline"></i> 12/09
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="pt-2 border-top">
                      <a
                        class="btn btn-sm btn-link font-size-14 w-100 text-center"
                        href="javascript:void(0)"
                      >
                        <i class="uil uil-angle-right-b mr-1"></i> View More..
                      </a>
                    </div>
                  </b-dropdown>
                  <b-dropdown
                    variant="link"
                    toggle-class="shadow-none text-dark"
                    right
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <li><b-dropdown-item>Action</b-dropdown-item></li>
                    <li><b-dropdown-item>Another action</b-dropdown-item></li>
                    <li>
                      <b-dropdown-item>Something else here</b-dropdown-item>
                    </li>
                  </b-dropdown>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-lg-5">
                <div class="mt-3">
                  <ul class="text-muted">
                    <li class="py-1">Sed ut perspiciatis unde omnis iste</li>
                    <li class="py-1">It va esser tam simplic quam</li>
                  </ul>
                </div>
              </div>
              <!-- end col -->

              <div class="col-lg-7">
                <div class="row">
                  <div class="col-lg-4 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i
                          class="uil uil-calendar-alt text-primary font-size-22"
                        ></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Start Date</h5>
                        <p class="text-muted mb-0">06 Mar, 2021</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->

                  <div class="col-lg-4 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i
                          class="uil uil-check-circle text-primary font-size-22"
                        ></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Due Date</h5>
                        <p class="text-muted mb-0">15 May, 2021</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- en card -->
        <b-tabs
          class="mt-n5 pt-2"
          content-class="card card-body mt-3"
          nav-class="nav-tabs-custom"
          nav-wrapper-class="nav-tabs-custom"
        >
          <b-tab title="Overview" active>
            <div class="row">
              <div class="col-xl-3 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                            <i class="uil uil-list-ul"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          Total Tasks
                        </p>
                        <h5 class="font-size-16 mb-0">21</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-3 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div
                            class="avatar-title bg-light text-primary rounded-circle font-size-18"
                          >
                            <i class="uil uil-check-circle"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          Completed Tasks
                        </p>
                        <h5 class="font-size-16 mb-0">10</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-3 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div
                            class="avatar-title bg-light text-primary rounded-circle font-size-18"
                          >
                            <i class="uil uil-users-alt"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">Members</p>
                        <h5 class="font-size-16 mb-0">12</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-3 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div
                            class="avatar-title bg-light text-primary rounded-circle font-size-18"
                          >
                            <i class="uil uil-clock-eight"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          Total Hours
                        </p>
                        <h5 class="font-size-16 mb-0">3224</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body-->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-lg-8">
                <div class="card border shadow-none h-100 mb-lg-0">
                  <div class="card-body">
                    <h5 class="card-title mb-4">Description</h5>

                    <div class="text-muted">
                      <P
                        >If several languages coalesce, the grammar of the
                        resulting language is more simple and regular than that
                        of the individual languages. The new common language
                        will be more simple and regular than the existing.</P
                      >
                      <p>
                        To achieve this, it would be necessary to have uniform
                        grammar, pronunciation and more common words.
                      </p>

                      <ul class="ps-3">
                        <li class="py-1">
                          Cras ultricies mi eu turpis hendrerit
                        </li>
                        <li class="py-1">Lorem ipsum dolor sit amet</li>
                        <li class="py-1">
                          Duis arcu tortor, suscipit eget
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <div class="card border shadow-none h-100 mb-lg-0">
                  <div class="card-body">
                    <h5 class="card-title mb-3">Task Status</h5>

                    <div>
                      <div class="py-3">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <h5 class="font-size-14">Design</h5>
                          </div>
                          <div class="flex-shrink-0">
                            <p class="text-muted mb-0">07/12</p>
                          </div>
                        </div>
                        <div
                          class="progress animated-progess custom-progress mt-2"
                        >
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 60%"
                            aria-valuenow="60"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>

                      <div class="py-3">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <h5 class="font-size-14">Development</h5>
                          </div>
                          <div class="flex-shrink-0">
                            <p class="text-muted mb-0">02/07</p>
                          </div>
                        </div>
                        <div
                          class="progress animated-progess custom-progress mt-2"
                        >
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 20%"
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>

                      <div class="py-3">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <h5 class="font-size-14">Other Tasks</h5>
                          </div>
                          <div class="flex-shrink-0">
                            <p class="text-muted mb-0">02/05</p>
                          </div>
                        </div>
                        <div
                          class="progress animated-progess custom-progress mt-2"
                        >
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 30%"
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end card -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </b-tab>
          <b-tab title="Tasks">
            <div class="d-flex align-items-start">
              <div class="flex-grow-1">
                <h5 class="card-title">Tasks</h5>
              </div>
              <div class="flex-shrink-0">
                <b-dropdown
                  right
                  variant="white"
                  toggle-class="btn-link text-dark p-0"
                  menu-class="dropdown-menu-end"
                >
                  <template v-slot:button-content>
                    <i class="uil uil-ellipsis-h"></i>
                  </template>
                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <div data-simplebar style="max-height: 325px;">
              <div class="table-responsive">
                <table class="table align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 40px;"></th>
                      <th scope="col" style="width: 140px;">Task ID</th>
                      <th scope="col">Task</th>
                      <th scope="col">Assignee</th>
                      <th scope="col">Status</th>
                      <th scope="col" style="width: 140px;">Action</th>
                    </tr>
                    <!-- end tr -->
                  </thead>
                  <!-- end thead -->
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check font-size-16">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="taskcheck01"
                          />
                          <label
                            class="form-check-label"
                            for="taskcheck01"
                          ></label>
                        </div>
                      </td>
                      <td><a href="#">DS-045</a></td>
                      <td>
                        <h5 class="font-size-14">
                          <a href="#" class="text-dark">Dashboard UI</a>
                        </h5>
                        <p class="text-muted mb-0">09 Mar, 2020</p>
                      </td>

                      <td>
                        <div class="avatar-group">
                          <div class="avatar-group-item">
                            <a
                              href="javascript: void(0);"
                              class="d-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Emily Surface"
                            >
                              <div class="avatar-sm">
                                <div
                                  class="avatar-title rounded-circle bg-primary"
                                >
                                  E
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="badge badge-soft-primary font-size-12"
                          >Open</span
                        >
                      </td>
                      <td>
                        <b-dropdown
                          variant="white"
                          toggle-class="font-size-16 px-2 text-muted p-0"
                          right
                        >
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-h"></i>
                          </template>
                          <b-dropdown-item>Action</b-dropdown-item>
                          <b-dropdown-item>Another action</b-dropdown-item>
                          <b-dropdown-item>Something else here</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <!-- end tr -->
                    <tr>
                      <td>
                        <div class="form-check font-size-16">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="taskcheck02"
                          />
                          <label
                            class="form-check-label"
                            for="taskcheck02"
                          ></label>
                        </div>
                      </td>
                      <td><a href="#">DS-044</a></td>
                      <td>
                        <h5 class="font-size-14">
                          <a href="#" class="text-dark">Calendar App Page</a>
                        </h5>
                        <p class="text-muted mb-0">08 Mar, 2020</p>
                      </td>

                      <td>
                        <div class="avatar-group">
                          <div class="avatar-group-item">
                            <a
                              href="javascript: void(0);"
                              class="d-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="James Scott"
                            >
                              <div class="avatar-sm">
                                <img
                                  src="@/assets/images/users/avatar-2.jpg"
                                  alt=""
                                  class="img-fluid rounded-circle"
                                />
                              </div>
                            </a>
                          </div>
                          <div class="avatar-group-item">
                            <a
                              href="javascript: void(0);"
                              class="d-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Lynn Hackett"
                            >
                              <div class="avatar-sm">
                                <div
                                  class="avatar-title rounded-circle bg-info"
                                >
                                  E
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="badge badge-soft-primary font-size-12"
                          >Open</span
                        >
                      </td>
                      <td>
                        <b-dropdown
                          variant="white"
                          toggle-class="font-size-16 px-2 text-muted p-0"
                          right
                        >
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-h"></i>
                          </template>
                          <b-dropdown-item>Action</b-dropdown-item>
                          <b-dropdown-item>Another action</b-dropdown-item>
                          <b-dropdown-item>Something else here</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <!-- end tr -->
                    <tr>
                      <td>
                        <div class="form-check font-size-16">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="taskcheck03"
                          />
                          <label
                            class="form-check-label"
                            for="taskcheck03"
                          ></label>
                        </div>
                      </td>
                      <td><a href="#">DS-043</a></td>
                      <td>
                        <h5 class="font-size-14">
                          <a href="#" class="text-dark"
                            >Authentication Page Design</a
                          >
                        </h5>
                        <p class="text-muted mb-0">07 Mar, 2020</p>
                      </td>
                      <td>
                        <div class="avatar-group">
                          <div class="avatar-group-item">
                            <a
                              href="javascript: void(0);"
                              class="d-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Emily Surface"
                            >
                              <div class="avatar-sm">
                                <div
                                  class="avatar-title rounded-circle bg-danger"
                                >
                                  E
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="badge badge-soft-warning font-size-12"
                          >Inprogress</span
                        >
                      </td>
                      <td>
                        <b-dropdown
                          variant="white"
                          toggle-class="font-size-16 px-2 text-muted p-0"
                          right
                        >
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-h"></i>
                          </template>
                          <b-dropdown-item>Action</b-dropdown-item>
                          <b-dropdown-item>Another action</b-dropdown-item>
                          <b-dropdown-item>Something else here</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <!-- end tr -->
                    <tr>
                      <td>
                        <div class="form-check font-size-16">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="taskcheck04"
                          />
                          <label
                            class="form-check-label"
                            for="taskcheck04"
                          ></label>
                        </div>
                      </td>
                      <td><a href="#">DS-042</a></td>
                      <td>
                        <h5 class="font-size-14">
                          <a href="#" class="text-dark">Component Pages</a>
                        </h5>
                        <p class="text-muted mb-0">06 Mar, 2020</p>
                      </td>

                      <td>
                        <div class="avatar-group">
                          <div class="avatar-group-item">
                            <a
                              href="javascript: void(0);"
                              class="d-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="James Scott"
                            >
                              <div class="avatar-sm">
                                <img
                                  src="@/assets/images/users/avatar-2.jpg"
                                  alt=""
                                  class="img-fluid rounded-circle"
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="badge badge-soft-warning font-size-12"
                          >Inprogress</span
                        >
                      </td>
                      <td>
                        <b-dropdown
                          variant="white"
                          toggle-class="font-size-16 px-2 text-muted p-0"
                          right
                        >
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-h"></i>
                          </template>
                          <b-dropdown-item>Action</b-dropdown-item>
                          <b-dropdown-item>Another action</b-dropdown-item>
                          <b-dropdown-item>Something else here</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <!-- end tr -->
                    <tr>
                      <td>
                        <div class="form-check font-size-16">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="taskcheck05"
                          />
                          <label
                            class="form-check-label"
                            for="taskcheck05"
                          ></label>
                        </div>
                      </td>
                      <td><a href="#">DS-041</a></td>
                      <td>
                        <h5 class="font-size-14">
                          <a href="#" class="text-dark">Admin layout design</a>
                        </h5>
                        <p class="text-muted mb-0">05 Mar, 2020</p>
                      </td>

                      <td>
                        <div class="avatar-group">
                          <div class="avatar-group-item">
                            <a
                              href="javascript: void(0);"
                              class="d-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="James Scott"
                            >
                              <div class="avatar-sm">
                                <img
                                  src="@/assets/images/users/avatar-2.jpg"
                                  alt=""
                                  class="img-fluid rounded-circle"
                                />
                              </div>
                            </a>
                          </div>
                          <div class="avatar-group-item">
                            <a
                              href="javascript: void(0);"
                              class="d-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Lynn Hackett"
                            >
                              <div class="avatar-sm">
                                <div
                                  class="avatar-title rounded-circle bg-info"
                                >
                                  L
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="badge badge-soft-success font-size-12"
                          >Completed</span
                        >
                      </td>
                      <td>
                        <b-dropdown
                          variant="white"
                          toggle-class="font-size-16 px-2 text-muted p-0"
                          right
                        >
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-h"></i>
                          </template>
                          <b-dropdown-item>Action</b-dropdown-item>
                          <b-dropdown-item>Another action</b-dropdown-item>
                          <b-dropdown-item>Something else here</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <!-- end tr -->
                  </tbody>
                  <!-- end tbody -->
                </table>
                <!-- end table -->
              </div>
            </div>
          </b-tab>
          <b-tab title="Team">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Member List
                    <span class="text-muted fw-normal ms-2">(12)</span>
                  </h5>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3"
                >
                  <div>
                    <ul class="nav nav-pills">
                      <li
                        class="nav-item"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="List"
                      >
                        <a class="nav-link" href="#list"
                          ><i class="uil uil-list-ul"></i
                        ></a>
                      </li>
                      <li
                        class="nav-item"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Grid"
                      >
                        <a class="nav-link active" href="#"
                          ><i class="uil uil-apps"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a href="#" class="btn btn-light"
                      ><i class="uil uil-plus me-1"></i> Add New</a
                    >
                  </div>

                  <b-dropdown
                    right
                    variant="white"
                    size="sm"
                    toggle-class="btn-link text-dark"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item>Action</b-dropdown-item>
                    <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else here</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="row">
              <div class="col-xl-4 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 avatar rounded-circle me-3">
                        <img
                          src="@/assets/images/users/avatar-1.jpg"
                          alt=""
                          class="img-fluid rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark">Donald Risher</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          Full Stack Developer
                        </p>
                      </div>
                      <b-dropdown
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <feather
                            class="icon-xs"
                            type="more-horizontal"
                          ></feather>
                        </template>
                        <b-dropdown-item>Edit</b-dropdown-item>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="btn-group btn-icon" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Profile"
                    >
                      <i class="uil uil-user"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Message"
                    >
                      <i class="uil uil-envelope-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Projects"
                    >
                      <i class="uil uil-chart-pie"></i>
                    </button>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-4 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar rounded-circle">
                          <img
                            src="@/assets/images/users/avatar-2.jpg"
                            alt=""
                            class="img-fluid rounded-circle"
                          />
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark">Helen Barron</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          Web Designer
                        </p>
                      </div>

                      <b-dropdown
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <feather
                            class="icon-xs"
                            type="more-horizontal"
                          ></feather>
                        </template>
                        <b-dropdown-item>Edit</b-dropdown-item>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="btn-group btn-icon" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Profile"
                    >
                      <i class="uil uil-user"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Message"
                    >
                      <i class="uil uil-envelope-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Projects"
                    >
                      <i class="uil uil-chart-pie"></i>
                    </button>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-4 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar rounded-circle">
                          <img
                            src="@/assets/images/users/avatar-3.jpg"
                            alt=""
                            class="img-fluid rounded-circle"
                          />
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark">Philip Theroux</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          UI/UX Designer
                        </p>
                      </div>
                      <b-dropdown
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <feather
                            class="icon-xs"
                            type="more-horizontal"
                          ></feather>
                        </template>
                        <b-dropdown-item>Edit</b-dropdown-item>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="btn-group btn-icon" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Profile"
                    >
                      <i class="uil uil-user"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Message"
                    >
                      <i class="uil uil-envelope-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Projects"
                    >
                      <i class="uil uil-chart-pie"></i>
                    </button>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-4 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar rounded-circle">
                          <img
                            src="@/assets/images/users/avatar-4.jpg"
                            alt=""
                            class="img-fluid rounded-circle"
                          />
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark">Donald Risher</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          Backend Developer
                        </p>
                      </div>
                      <b-dropdown
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <feather
                            class="icon-xs"
                            type="more-horizontal"
                          ></feather>
                        </template>
                        <b-dropdown-item>Edit</b-dropdown-item>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <!-- end cardbody -->
                  <div class="btn-group btn-icon" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Profile"
                    >
                      <i class="uil uil-user"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Message"
                    >
                      <i class="uil uil-envelope-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Projects"
                    >
                      <i class="uil uil-chart-pie"></i>
                    </button>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-4 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar rounded-circle">
                          <img
                            src="@/assets/images/users/avatar-5.jpg"
                            alt=""
                            class="img-fluid rounded-circle"
                          />
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark">Gerald Moyer</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          Backend Developer
                        </p>
                      </div>
                      <b-dropdown
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <feather
                            class="icon-xs"
                            type="more-horizontal"
                          ></feather>
                        </template>
                        <b-dropdown-item>Edit</b-dropdown-item>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="btn-group btn-icon" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Profile"
                    >
                      <i class="uil uil-user"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Message"
                    >
                      <i class="uil uil-envelope-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Projects"
                    >
                      <i class="uil uil-chart-pie"></i>
                    </button>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-4 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar rounded-circle">
                          <img
                            src="@/assets/images/users/avatar-6.jpg"
                            alt=""
                            class="img-fluid rounded-circle"
                          />
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark">Sharon Carver</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          Frontend Developer
                        </p>
                      </div>
                      <b-dropdown
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <feather
                            class="icon-xs"
                            type="more-horizontal"
                          ></feather>
                        </template>
                        <b-dropdown-item>Edit</b-dropdown-item>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <!-- end card body -->
                  <div class="btn-group btn-icon" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Profile"
                    >
                      <i class="uil uil-user"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Message"
                    >
                      <i class="uil uil-envelope-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Projects"
                    >
                      <i class="uil uil-chart-pie"></i>
                    </button>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
            <div class="row g-0">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">Showing 1 to 2 of 12 entries</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <ul class="pagination pagination-rounded mb-sm-0">
                    <li class="page-item">
                      <a href="#" class="page-link"
                        ><i class="mdi mdi-chevron-left"></i
                      ></a>
                    </li>
                    <li class="page-item active">
                      <a href="#" class="page-link">1</a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">2</a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">3</a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">4</a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link">5</a>
                    </li>
                    <li class="page-item">
                      <a href="#" class="page-link"
                        ><i class="mdi mdi-chevron-right"></i
                      ></a>
                    </li>
                  </ul>
                  <!-- end ul -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </b-tab>

          <b-tab title="Activities">
            <div class="card-header">
              <h5 class="card-title mb-0">Activities</h5>
            </div>
            <div class="card-body">
              <div data-simplebar style="max-height: 280px;">
                <div class="list-group list-group-flush">
                  <div class="list-group-item py-3">
                    <div class="d-flex aligm-items-start">
                      <div class="flex-shrink-0 me-3">
                        <img
                          src="@/assets/images/users/avatar-2.jpg"
                          alt=""
                          class="avatar-sm rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark"
                            >John Patino</a
                          >
                          <small class="text-muted ms-1">09:34 AM</small>
                        </h5>
                        <p class="text-muted mb-0">
                          Donec quam felis ultricies nec, pellentesque eu
                          pretium quis
                        </p>
                      </div>
                      <div class="flex-shrink-0 ms-2">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a
                              href="javascript: void(0);"
                              class="text-muted font-size-15 px-1"
                            >
                              <i class="uil uil-smile"></i>
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <b-dropdown
                              toggle-class="text-muted font-size-18 px-2"
                              variant="white"
                            >
                              <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                              </template>
                              <b-dropdown-item
                                ><i
                                  class="uil uil-copy align-bottom text-muted me-2"
                                ></i>
                                Copy</b-dropdown-item
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-comment-alt-notes align-bottom text-muted me-2"
                                ></i>
                                Reply</b-dropdown-item
                              >
                              <b-dropdown-divider
                                ><i
                                  class="uil uil-share align-bottom text-muted me-2"
                                ></i>
                                Forward</b-dropdown-divider
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-trash-alt align-bottom text-muted me-2"
                                ></i>
                                Remove</b-dropdown-item
                              >
                            </b-dropdown>
                          </li>
                        </ul>
                        <!-- end ul -->
                      </div>
                    </div>
                  </div>
                  <!-- end list group -->

                  <div class="list-group-item py-3">
                    <div class="d-flex aligm-items-center">
                      <div class="flex-shrink-0 me-3">
                        <img
                          src="@/assets/images/users/avatar-1.jpg"
                          alt=""
                          class="avatar-sm rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark"
                            >Kate Daniels</a
                          >
                          <small class="text-muted ms-1">09:45 AM</small>
                        </h5>
                        <p class="text-muted mb-0">
                          Aenean imperdiet. etiam ultricies nisi vel augue.
                        </p>
                      </div>
                      <div class="flex-shrink-0 ms-2">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a
                              href="javascript: void(0);"
                              class="text-muted font-size-15 px-1"
                            >
                              <i class="uil uil-smile"></i>
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <b-dropdown
                              toggle-class="text-muted font-size-18 px-2"
                              variant="white"
                            >
                              <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                              </template>
                              <b-dropdown-item
                                ><i
                                  class="uil uil-copy align-bottom text-muted me-2"
                                ></i>
                                Copy</b-dropdown-item
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-comment-alt-notes align-bottom text-muted me-2"
                                ></i>
                                Reply</b-dropdown-item
                              >
                              <b-dropdown-divider
                                ><i
                                  class="uil uil-share align-bottom text-muted me-2"
                                ></i>
                                Forward</b-dropdown-divider
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-trash-alt align-bottom text-muted me-2"
                                ></i>
                                Remove</b-dropdown-item
                              >
                            </b-dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- end list group -->

                  <div class="list-group-item py-3">
                    <div class="d-flex aligm-items-center">
                      <div class="flex-shrink-0 me-3">
                        <img
                          src="@/assets/images/users/avatar-2.jpg"
                          alt=""
                          class="avatar-sm rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark"
                            >John Patino</a
                          >
                          <small class="text-muted ms-1">10:15 AM</small>
                        </h5>
                        <div class="row">
                          <div class="col-lg-4 col-sm-6">
                            <div
                              class="card border p-2 shadow-none mt-2 mb-2"
                            >
                              <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 avatar-sm me-3">
                                  <span
                                    class="avatar-title bg-light text-primary rounded font-size-20"
                                  >
                                    <i class="uil uil-scenery"></i>
                                  </span>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                  <h5 class="font-size-12 mb-1 text-truncate">
                                    <a
                                      href="javascript:void(0);"
                                      class="text-dark"
                                      >Image-1.jpg</a
                                    >
                                  </h5>
                                  <p class="text-muted mb-0 font-size-12">
                                    1.2 MB
                                  </p>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                  <a
                                    href="javascript:void(0);"
                                    class="px-2 font-size-18 text-muted"
                                  >
                                    <i class="uil uil-download-alt"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!-- end card -->
                          </div>
                          <!-- end col -->
                        </div>
                        <!-- end row -->
                      </div>
                      <div class="flex-shrink-0 ms-2">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a
                              href="javascript: void(0);"
                              class="text-muted font-size-15 px-1"
                            >
                              <i class="uil uil-smile"></i>
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <b-dropdown
                              toggle-class="text-muted font-size-18 px-2"
                              variant="white"
                            >
                              <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                              </template>
                              <b-dropdown-item
                                ><i
                                  class="uil uil-copy align-bottom text-muted me-2"
                                ></i>
                                Copy</b-dropdown-item
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-comment-alt-notes align-bottom text-muted me-2"
                                ></i>
                                Reply</b-dropdown-item
                              >
                              <b-dropdown-divider
                                ><i
                                  class="uil uil-share align-bottom text-muted me-2"
                                ></i>
                                Forward</b-dropdown-divider
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-trash-alt align-bottom text-muted me-2"
                                ></i>
                                Remove</b-dropdown-item
                              >
                            </b-dropdown>
                          </li>
                          <!-- end li -->
                        </ul>
                        <!-- end ul -->
                      </div>
                    </div>
                  </div>
                  <!-- end list group -->

                  <div class="list-group-item py-3">
                    <div class="d-flex aligm-items-center">
                      <div class="flex-shrink-0 flex-shrink-0 avatar-sm me-3">
                        <div
                          class="avatar-title rounded-circle bg-light text-primary"
                        >
                          J
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark"
                            >Jerry Macleod</a
                          >
                          <small class="text-muted ms-1">11:34 AM</small>
                        </h5>
                        <p class="text-muted mb-0">
                          Sed consequat, leo eget augue velit
                        </p>
                      </div>
                      <div class="flex-shrink-0 ms-2">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a
                              href="javascript: void(0);"
                              class="text-muted font-size-15 px-1"
                            >
                              <i class="uil uil-smile"></i>
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <b-dropdown
                              toggle-class="text-muted font-size-18 px-2"
                              variant="white"
                            >
                              <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                              </template>
                              <b-dropdown-item
                                ><i
                                  class="uil uil-copy align-bottom text-muted me-2"
                                ></i>
                                Copy</b-dropdown-item
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-comment-alt-notes align-bottom text-muted me-2"
                                ></i>
                                Reply</b-dropdown-item
                              >
                              <b-dropdown-divider
                                ><i
                                  class="uil uil-share align-bottom text-muted me-2"
                                ></i>
                                Forward</b-dropdown-divider
                              >
                              <b-dropdown-item
                                ><i
                                  class="uil uil-trash-alt align-bottom text-muted me-2"
                                ></i>
                                Remove</b-dropdown-item
                              >
                            </b-dropdown>
                          </li>
                        </ul>
                        <!-- end ul -->
                      </div>
                    </div>
                  </div>
                  <!-- end list group -->
                </div>
              </div>
              <div class="d-flex chat-input-section align-items-start pt-3">
                <div class="flex-shrink-0 d-none d-sm-block me-3">
                  <img
                    src="@/assets/images/users/avatar-4.jpg"
                    alt=""
                    class="avatar-sm rounded-circle"
                  />
                </div>
                <div class="flex-grow-1">
                  <div class="position-relative d-flex align-items-start">
                    <input
                      type="text"
                      class="form-control chat-input"
                      placeholder="Some text value..."
                    />
                    <div class="chat-input-links d-flex align-items-start">
                      <button
                        type="button"
                        class="btn btn-link text-muted"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Emoji"
                        aria-label="Emoji"
                      >
                        <i class="uil uil-smile"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-link text-muted"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Attachments"
                        aria-label="Attachments"
                      >
                        <i class="uil uil-paperclip"></i>
                      </button>
                      <button type="button" class="btn btn-primary">
                        <i class="uil uil-message"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
          </b-tab>
        </b-tabs>
        <!-- Nav tabs -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
